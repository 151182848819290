.terms-container{
    padding: 100px 200px;
    font-family:Arial, Helvetica, sans-serif;
}
.terms-container div{
    margin: 20px 50px;
}
.terms-container h2{
    margin-top: 50px;
}
.terms-container li{
    margin-top: 50px;
}
.terms-container p{
    line-height: 20px;
}

@media (max-width: 500px) {
    .terms-container{
        padding: 10px 10px;
    }
    .terms-container div{
        margin: 20px 20px;
    }
}