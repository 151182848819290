.home-container{
    width: 100%;
    height: 100vh;
    background: var(--color1);
    position: relative;
    display: flex;
    align-items: center;
}

.home-picture1{
    width: 50%;
    position: absolute;
    right: 5%;
    margin-bottom: -100px;

}
.home-picture2{
    display: none;
}
.home-stores{
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 30px;
    bottom: 120px;
}
.home-stores img{
    width: 160px;
}

/* -----------------Title-------------------- */
.home-title{
    position: absolute;
    left: 10%;
    display: flex;
    flex-direction: column;
    color: var(--color2);
    margin-top: -100px;
}
.home-title img{
    width: 60%;
    margin-bottom: 20px;
}
.home-title h1{
    margin: 0 0 20px 0;
    font-weight: 400;
    font-size: 28px;
    
}
.home-button{
    width: 50%;
    border-radius: 25px;
    border-width: 1px;
    background: var(--color1);
    border-color: var(--color2);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    color: var(--color2);
}
.home-button:hover{
    opacity: .7;
    cursor: pointer;
    color: var(--color3);
    border-color: var(--color3);
}
.home-button:active{
    background: var(--color2);
}
.home-redes{
    position: absolute;
    right: 30px;
    top: 20px;
}
.home-redes img{
    margin-right: 10px;
}
.home-redes img:hover{
    cursor: pointer;
    opacity: .6;
}



@media (max-width: 650px) {
    .home-picture1{
        display: none;
    }
    .home-picture2{
        display:block;
        position: absolute;
        margin-bottom: -100px;
        right: 0;
        width: 90%;
    }
    .home-title{
        top: 30%;
    }
    .home-title h1{
        font-size: 16PX;
    }
    .home-title p{
        font-size: 14PX;
    }
    .home-button{
        width: 70%;
    }
    .home-redes{
        top: auto;
        bottom: 5%;
        display: flex;
        width: 100%;
        justify-content: center;
        left: auto;
        right: auto;
    }
    .home-redes img{
        width: 13%;
    }
    .home-stores{
        width: 100%;
        bottom: 150px;
        right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}