.nav-dashboard-admin{
}

.nav-isActive{
    text-decoration-line: none;
    
}

.nav-dashboard-admin:hover{
    opacity: .7;
}