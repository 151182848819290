.teams-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: 'center';
    animation: 3s linear enter;
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.class-none{
    display: none;
}

@keyframes enter {
    0%{
        opacity: 0;
        height: 0%;

    }
    100%{
        opacity: 1;
        transform: scale(1);
        height: 100%;


    }
}