@media all {
:root{
    --font1: 'Inter', sans-serif;
    --color1: #FF6D00;
    --color2: white;
    --color3: black;

}
html, body{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    font-family: 'Inter', sans-serif;
    font-family: var(--font1);
}
.map-marker{
    width: 10px;
    height: 10px;
}


}
@media all {
.home-container{
    width: 100%;
    height: 100vh;
    background: var(--color1);
    position: relative;
    display: flex;
    align-items: center;
}

.home-picture1{
    width: 50%;
    position: absolute;
    right: 5%;
    margin-bottom: -100px;

}
.home-picture2{
    display: none;
}
.home-stores{
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 30px;
    bottom: 120px;
}
.home-stores img{
    width: 160px;
}

/* -----------------Title-------------------- */
.home-title{
    position: absolute;
    left: 10%;
    display: flex;
    flex-direction: column;
    color: var(--color2);
    margin-top: -100px;
}
.home-title img{
    width: 60%;
    margin-bottom: 20px;
}
.home-title h1{
    margin: 0 0 20px 0;
    font-weight: 400;
    font-size: 28px;
    
}
.home-button{
    width: 50%;
    border-radius: 25px;
    border-width: 1px;
    background: var(--color1);
    border-color: var(--color2);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    color: var(--color2);
}
.home-button:hover{
    opacity: .7;
    cursor: pointer;
    color: var(--color3);
    border-color: var(--color3);
}
.home-button:active{
    background: var(--color2);
}
.home-redes{
    position: absolute;
    right: 30px;
    top: 20px;
}
.home-redes img{
    margin-right: 10px;
}
.home-redes img:hover{
    cursor: pointer;
    opacity: .6;
}



@media (max-width: 650px) {
    .home-picture1{
        display: none;
    }
    .home-picture2{
        display:block;
        position: absolute;
        margin-bottom: -100px;
        right: 0;
        width: 90%;
    }
    .home-title{
        top: 30%;
    }
    .home-title h1{
        font-size: 16PX;
    }
    .home-title p{
        font-size: 14PX;
    }
    .home-button{
        width: 70%;
    }
    .home-redes{
        top: auto;
        bottom: 5%;
        display: flex;
        width: 100%;
        justify-content: center;
        left: auto;
        right: auto;
    }
    .home-redes img{
        width: 13%;
    }
    .home-stores{
        width: 100%;
        bottom: 150px;
        right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
}
@media all {
.terms-container{
    padding: 100px 200px;
    font-family:Arial, Helvetica, sans-serif;
}
.terms-container div{
    margin: 20px 50px;
}
.terms-container h2{
    margin-top: 50px;
}
.terms-container li{
    margin-top: 50px;
}
.terms-container p{
    line-height: 20px;
}

@media (max-width: 500px) {
    .terms-container{
        padding: 10px 10px;
    }
    .terms-container div{
        margin: 20px 20px;
    }
}
}
@media all {
.header-container{
    background: var(--color2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 15vh;
    max-height: 60px;
    padding: 15px 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    z-index: 1;
    position: absolute;
}
.header-container img{
    width: 200px;
    margin: 0 0 0 50px;
}
.nav-container ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-container li{
    margin-left: 50px;
    font-weight: bold;
    color: var(--color2);
    text-decoration: none;
}

@media (max-width: 320px) {
    .header-container{
        padding: 20px 0px;
        justify-content: center;
    }
    .header-container img{
        width: 50%;
    }
}
}
@media all {
.nav-dashboard-admin{
}

.nav-isActive{
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    
}

.nav-dashboard-admin:hover{
    opacity: .7;
}
}
@media all {

.create-section1b label{
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 10px;
    font-weight: bold;
}

.create-admin-input{
    height: 20px;
    width: 94%;
    padding: 8px 5px;
    background-color: #E5E5E7;
    border-radius: 5px;
    border-color: gray;
    border-style: none;
}
.create-admin-textarea{
    height: 120px;
    padding: 10px;
}

}
@media all {
.teams-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: 'center';
    -webkit-animation: 3s linear enter;
            animation: 3s linear enter;
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.class-none{
    display: none;
}

@-webkit-keyframes enter {
    0%{
        opacity: 0;
        height: 0%;

    }
    100%{
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
        height: 100%;


    }
}

@keyframes enter {
    0%{
        opacity: 0;
        height: 0%;

    }
    100%{
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
        height: 100%;


    }
}
}
@media all {
.box-home{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: solid black 1px;
}

.box-home:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}


}
@media all {
.container-teams-moderation{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    width: 100%;
    max-height: 100%;
    overflow-y: hidden;
}
}
@media all {
.loader {
    width: 360px;
    height: 100px;
    display: block;
    position: relative;
    background-image: linear-gradient(100deg, transparent, rgba(38, 50, 56, 0.5) 50%, transparent 80%), linear-gradient(#FFF 20px, transparent 0), linear-gradient(#FFF 20px, transparent 0), linear-gradient(#FFF 20px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 100px,  125px 20px, 260px 20px, 260px 20px;
    background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
    box-sizing: border-box;
    -webkit-animation: animloader 1s linear infinite;
            animation: animloader 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @-webkit-keyframes animloader {
    0% {
      background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
    }
    100% {
      background-position: 100% 0, 120px 0, 120px 40px, 120px 80px;
    }
  }
  
  @keyframes animloader {
    0% {
      background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
    }
    100% {
      background-position: 100% 0, 120px 0, 120px 40px, 120px 80px;
    }
  }


  /* 3 */
  .loader-3 {
    color: #FFF;
    position: relative;
    display: inline-block;
    
    font-family: Arial, Helvetica, sans-serif;
    font-size: 48px;
    letter-spacing: 4px;
    box-sizing: border-box;
  }
  .loader-3::before {
    content: '';  
    position: absolute;
    right: 70px;
    bottom: 10px;
    height: 28px;
    width: 5.15px;
    background: currentColor;
    box-sizing: border-box;
    -webkit-animation: animloader1 1s linear infinite alternate;
            animation: animloader1 1s linear infinite alternate;
  }
  .loader-3::after {
    content: '';  
    width: 10px;
    height: 10px;
    position: absolute;
    left: 125px;
    top: 2px;
    border-radius: 50%;
    background: red;
    box-sizing: border-box;
    -webkit-animation: animloader2 1s linear infinite alternate;
            animation: animloader2 1s linear infinite alternate;
  }
  
  @-webkit-keyframes animloader2 {
    0% {
      -webkit-transform: translate(0px, 0px) scaleX(1);
              transform: translate(0px, 0px) scaleX(1);
    }
    14% {
      -webkit-transform: translate(-12px, -16px) scaleX(1.05);
              transform: translate(-12px, -16px) scaleX(1.05);
    }
    28% {
      -webkit-transform: translate(-27px, -28px) scaleX(1.07);
              transform: translate(-27px, -28px) scaleX(1.07);
    }
    42% {
      -webkit-transform: translate(-46px, -35px) scaleX(1.1);
              transform: translate(-46px, -35px) scaleX(1.1);
    }
    57% {
      -webkit-transform: translate(-70px, -37px) scaleX(1.1);
              transform: translate(-70px, -37px) scaleX(1.1);
    }
    71% {
      -webkit-transform: translate(-94px, -32px) scaleX(1.07);
              transform: translate(-94px, -32px) scaleX(1.07);
    }
    85% {
      -webkit-transform: translate(-111px, -22px) scaleX(1.05);
              transform: translate(-111px, -22px) scaleX(1.05);
    }
    100% {
      -webkit-transform: translate(-125px, -9px) scaleX(1);
              transform: translate(-125px, -9px) scaleX(1);
    }
  }
  
  @keyframes animloader2 {
    0% {
      -webkit-transform: translate(0px, 0px) scaleX(1);
              transform: translate(0px, 0px) scaleX(1);
    }
    14% {
      -webkit-transform: translate(-12px, -16px) scaleX(1.05);
              transform: translate(-12px, -16px) scaleX(1.05);
    }
    28% {
      -webkit-transform: translate(-27px, -28px) scaleX(1.07);
              transform: translate(-27px, -28px) scaleX(1.07);
    }
    42% {
      -webkit-transform: translate(-46px, -35px) scaleX(1.1);
              transform: translate(-46px, -35px) scaleX(1.1);
    }
    57% {
      -webkit-transform: translate(-70px, -37px) scaleX(1.1);
              transform: translate(-70px, -37px) scaleX(1.1);
    }
    71% {
      -webkit-transform: translate(-94px, -32px) scaleX(1.07);
              transform: translate(-94px, -32px) scaleX(1.07);
    }
    85% {
      -webkit-transform: translate(-111px, -22px) scaleX(1.05);
              transform: translate(-111px, -22px) scaleX(1.05);
    }
    100% {
      -webkit-transform: translate(-125px, -9px) scaleX(1);
              transform: translate(-125px, -9px) scaleX(1);
    }
  }
  
  @-webkit-keyframes animloader1 {
    0% {
      box-shadow: 0 -6px, -122.9px -8px;
    }
    25%, 75% {
      box-shadow: 0 0px, -122.9px -8px;
    }
    100% {
      box-shadow: 0 0px, -122.9px -16px;
    }
  }
  
  @keyframes animloader1 {
    0% {
      box-shadow: 0 -6px, -122.9px -8px;
    }
    25%, 75% {
      box-shadow: 0 0px, -122.9px -8px;
    }
    100% {
      box-shadow: 0 0px, -122.9px -16px;
    }
  }

  /* 2 */

  .loader-2 {
    position: relative;
    width: 108px;
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
  }
  .loader-2::after , 
  .loader-2::before  {
    content: '';
    display: inline-block;
    width: 48px;
    height: 48px;
    background-color: #FFF;
    background-image:  radial-gradient(circle 14px, #0d161b 100%, transparent 0);
    background-repeat: no-repeat;
    border-radius: 50%;
    -webkit-animation: eyeMove 10s infinite , blink 10s infinite;
            animation: eyeMove 10s infinite , blink 10s infinite;
  }
  @-webkit-keyframes eyeMove {
    0%  , 10% {     background-position: 0px 0px}
    13%  , 40% {     background-position: -15px 0px}
    43%  , 70% {     background-position: 15px 0px}
    73%  , 90% {     background-position: 0px 15px}
    93%  , 100% {     background-position: 0px 0px}
  }
  @keyframes eyeMove {
    0%  , 10% {     background-position: 0px 0px}
    13%  , 40% {     background-position: -15px 0px}
    43%  , 70% {     background-position: 15px 0px}
    73%  , 90% {     background-position: 0px 15px}
    93%  , 100% {     background-position: 0px 0px}
  }
  @-webkit-keyframes blink {
    0%  , 10% , 12% , 20%, 22%, 40%, 42% , 60%, 62%,  70%, 72% , 90%, 92%, 98% , 100%
    { height: 48px}
    11% , 21% ,41% , 61% , 71% , 91% , 99%
    { height: 18px}
  }
  @keyframes blink {
    0%  , 10% , 12% , 20%, 22%, 40%, 42% , 60%, 62%,  70%, 72% , 90%, 92%, 98% , 100%
    { height: 48px}
    11% , 21% ,41% , 61% , 71% , 91% , 99%
    { height: 18px}
  }


  .loader-4 {
    width: 360px;
    height: 100px;
    display: block;
    position: relative;
    background-image: linear-gradient(100deg, transparent, rgba(38, 50, 56, 0.5) 50%, transparent 80%), linear-gradient(#FFF 20px, transparent 0), linear-gradient(#FFF 20px, transparent 0), linear-gradient(#FFF 20px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 100px,  125px 20px, 260px 20px, 260px 20px;
    background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
    box-sizing: border-box;
    -webkit-animation: animloader 1s linear infinite;
            animation: animloader 1s linear infinite;
  }
  .loader-4::after {
    content: '';  
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @keyframes animloader {
    0% {
      background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
    }
    100% {
      background-position: 100% 0, 120px 0, 120px 40px, 120px 80px;
    }
  }
}
@media all {

.container-ideas{
    display: grid;
    grid-template-columns: repeat(4,1fr) ;
    justify-items: center;
    height: 80%;
    overflow-y: hidden;
    width: 100%;
}
}
@media all {
.pointers-container{
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
}






