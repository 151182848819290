.header-container{
    background: var(--color2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 15vh;
    max-height: 60px;
    padding: 15px 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    z-index: 1;
    position: absolute;
}
.header-container img{
    width: 200px;
    margin: 0 0 0 50px;
}
.nav-container ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-container li{
    margin-left: 50px;
    font-weight: bold;
    color: var(--color2);
    text-decoration: none;
}

@media (max-width: 320px) {
    .header-container{
        padding: 20px 0px;
        justify-content: center;
    }
    .header-container img{
        width: 50%;
    }
}