
.create-section1b label{
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 10px;
    font-weight: bold;
}

.create-admin-input{
    height: 20px;
    width: 94%;
    padding: 8px 5px;
    background-color: #E5E5E7;
    border-radius: 5px;
    border-color: gray;
    border-style: none;
}
.create-admin-textarea{
    height: 120px;
    padding: 10px;
}
