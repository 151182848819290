.loader {
    width: 360px;
    height: 100px;
    display: block;
    position: relative;
    background-image: linear-gradient(100deg, transparent, rgba(38, 50, 56, 0.5) 50%, transparent 80%), linear-gradient(#FFF 20px, transparent 0), linear-gradient(#FFF 20px, transparent 0), linear-gradient(#FFF 20px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 100px,  125px 20px, 260px 20px, 260px 20px;
    background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @keyframes animloader {
    0% {
      background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
    }
    100% {
      background-position: 100% 0, 120px 0, 120px 40px, 120px 80px;
    }
  }


  /* 3 */
  .loader-3 {
    color: #FFF;
    position: relative;
    display: inline-block;
    
    font-family: Arial, Helvetica, sans-serif;
    font-size: 48px;
    letter-spacing: 4px;
    box-sizing: border-box;
  }
  .loader-3::before {
    content: '';  
    position: absolute;
    right: 70px;
    bottom: 10px;
    height: 28px;
    width: 5.15px;
    background: currentColor;
    box-sizing: border-box;
    animation: animloader1 1s linear infinite alternate;
  }
  .loader-3::after {
    content: '';  
    width: 10px;
    height: 10px;
    position: absolute;
    left: 125px;
    top: 2px;
    border-radius: 50%;
    background: red;
    box-sizing: border-box;
    animation: animloader2 1s linear infinite alternate;
  }
  
  @keyframes animloader2 {
    0% {
      transform: translate(0px, 0px) scaleX(1);
    }
    14% {
      transform: translate(-12px, -16px) scaleX(1.05);
    }
    28% {
      transform: translate(-27px, -28px) scaleX(1.07);
    }
    42% {
      transform: translate(-46px, -35px) scaleX(1.1);
    }
    57% {
      transform: translate(-70px, -37px) scaleX(1.1);
    }
    71% {
      transform: translate(-94px, -32px) scaleX(1.07);
    }
    85% {
      transform: translate(-111px, -22px) scaleX(1.05);
    }
    100% {
      transform: translate(-125px, -9px) scaleX(1);
    }
  }
  
  @keyframes animloader1 {
    0% {
      box-shadow: 0 -6px, -122.9px -8px;
    }
    25%, 75% {
      box-shadow: 0 0px, -122.9px -8px;
    }
    100% {
      box-shadow: 0 0px, -122.9px -16px;
    }
  }

  /* 2 */

  .loader-2 {
    position: relative;
    width: 108px;
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
  }
  .loader-2::after , 
  .loader-2::before  {
    content: '';
    display: inline-block;
    width: 48px;
    height: 48px;
    background-color: #FFF;
    background-image:  radial-gradient(circle 14px, #0d161b 100%, transparent 0);
    background-repeat: no-repeat;
    border-radius: 50%;
    animation: eyeMove 10s infinite , blink 10s infinite;
  }
  @keyframes eyeMove {
    0%  , 10% {     background-position: 0px 0px}
    13%  , 40% {     background-position: -15px 0px}
    43%  , 70% {     background-position: 15px 0px}
    73%  , 90% {     background-position: 0px 15px}
    93%  , 100% {     background-position: 0px 0px}
  }
  @keyframes blink {
    0%  , 10% , 12% , 20%, 22%, 40%, 42% , 60%, 62%,  70%, 72% , 90%, 92%, 98% , 100%
    { height: 48px}
    11% , 21% ,41% , 61% , 71% , 91% , 99%
    { height: 18px}
  }


  .loader-4 {
    width: 360px;
    height: 100px;
    display: block;
    position: relative;
    background-image: linear-gradient(100deg, transparent, rgba(38, 50, 56, 0.5) 50%, transparent 80%), linear-gradient(#FFF 20px, transparent 0), linear-gradient(#FFF 20px, transparent 0), linear-gradient(#FFF 20px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 100px,  125px 20px, 260px 20px, 260px 20px;
    background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }
  .loader-4::after {
    content: '';  
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @keyframes animloader {
    0% {
      background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
    }
    100% {
      background-position: 100% 0, 120px 0, 120px 40px, 120px 80px;
    }
  }