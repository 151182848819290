:root{
    --font1: 'Inter', sans-serif;
    --color1: #FF6D00;
    --color2: white;
    --color3: black;

}
html, body{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    font-family: var(--font1);
}
.map-marker{
    width: 10px;
    height: 10px;
}

